<template>
    <div>
        <flickity class="c-slider" ref="slider" :options="sliderOptions" v-images-loaded:on.progress="imgsLoaded">
            <figure class="c-slider__item" @click="openFullscreen" v-for="img in comp.settings.gallery" :key="img.meta.asset">
                <img class="c-slider__image" :src="siteURL + img.path">
            </figure>
        </flickity>

        <div class="c-modal" :class="{'c-modal--is-open' : showGallery}">
            <div class="c-modal__inner">
                <button class="c-modal__close" @click="closeFullscreen">&#x00D7;</button>
                <flickity class="c-slider c-slider--fullscreen" ref="sliderFullscreen" :options="sliderFullOptions" v-images-loaded:on.progress="imgsLoaded">
                    <img class="c-slider__item c-slider__item--full" v-for="img in comp.settings.gallery" :key="img.meta.asset" :src="siteURL + img.path">
                </flickity>
            </div>
        </div>
    </div>
</template>

<script>
  import Flickity from 'vue-flickity'
  import imagesLoaded from 'vue-images-loaded'

  export default {
    name: 'Comp_gallery',
    props: {
      comp: Object
    },

    directives: {
      imagesLoaded
    },

    data () {
      return {
        siteURL: null,
        showGallery: false,
        sliderOptions: {
          cellAlign: 'center',
          pageDots: false
        },
        sliderFullOptions: {
          cellAlign: 'center',
          pageDots: false
        }
      }
    },

    created () {
      this.siteURL = process.env.VUE_APP_BACKEND_URL
    },

    methods: {
      imgsLoaded () {
        let sliderInstance = this.$refs.slider
        let sliderFullInstance = this.$refs.sliderFullscreen
        sliderInstance.reloadCells()
        sliderFullInstance.reloadCells()
      },

      openFullscreen () {
        this.showGallery = true
      },

      closeFullscreen () {
        this.showGallery = false
      }
    },

    components: {
      'flickity': Flickity
    }
  }
</script>
